import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';

interface CartContextProps {
  showCart: boolean;
  setShowCart: Dispatch<SetStateAction<boolean>>;
  closeCart: () => void;
}

const CartContext = createContext<CartContextProps | undefined>(undefined);

interface CartProviderProps {
  children: ReactNode;
}

export const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
  const [showCart, setShowCart] = useState<boolean>(false);

  const closeCart = () => {
    setShowCart(false);
  };

  // Reset showCart to false on component unmount
  useEffect(() => {
    return () => {
      setShowCart(false);
    };
  }, []);

  return (
    <CartContext.Provider value={{ showCart, setShowCart, closeCart }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = (): CartContextProps => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};
